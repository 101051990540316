import { connect } from 'react-redux'
import ComingEvent, { Props } from '.'
import { ApplicationState } from '../../../../../reducers'
import { currentAndFutureEventsSelector } from '../../../../../data/selectors'

const mapStateToProps = (state: ApplicationState): Props => {
  const events = currentAndFutureEventsSelector(state)
  const filteredEvents = events.filter(
    event => event.type.toString() !== 'melbAirport' && event.slug !== 'ma-ff-feb-2025'
  )
  return {
    events: filteredEvents,
  }
}

export default connect(mapStateToProps)(ComingEvent)
