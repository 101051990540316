import React from 'react'
import { Link } from 'react-router-dom'
import SecondNavIcon from './SecondNavIcon'
import classnames from 'classnames'
import EventType from '../../../models/eventType'
import './SecondNavItem.scss'

type FieldProps = {
  name: string
  path?: string
  displayIcon?: boolean
  categoryMenuHandler?: () => void
  isCategoryMenuOpen?: boolean
  eventType?: EventType | null
}

class SecondNavItem extends React.Component<FieldProps> {
  render() {
    return (
      <div
        className={
          this.props.isCategoryMenuOpen !== undefined
            ? classnames('item__wrapper', { 'is-active': this.props.isCategoryMenuOpen })
            : 'item__wrapper'
        }
        onClick={this.props.categoryMenuHandler}
      >
        {this.props.displayIcon && <SecondNavIcon eventType={this.props.eventType} />}
        {this.props.path ? (
          this.props.path !== 'https://faq.clickfrenzy.com.au/support/home' ? (
            <Link
              to={this.props.path}
              className={`item__link item__link--${this.props.eventType}`}
            >
              {this.props.name}
            </Link>
          ) : (
            <a
              href={this.props.path}
              className={`item__link item__link--${this.props.eventType}`}
            >
              {this.props.name}
            </a>
          )
        ) : (
          <div
            className={`item__link item__link--${this.props.eventType}`}
            style={{ cursor: 'pointer' }}
          >
            {this.props.name}
          </div>
        )}
      </div>
    )
  }
}

export default SecondNavItem
