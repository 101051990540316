import { ApplicationState } from '../../reducers'
import CollectionSlider, { FieldProps } from './CollectionSlider'
import Collection from '../../models/collection'
import Banner from '../../models/banner'
import { connect } from 'react-redux'
import { dataLayerPushEvent } from '../../services/gtm'
import { currentEventSelector } from '../../data/selectors'
import { getCategoryUrlFromCategories, findCategoryBySlug } from '../category/utilities'
import { CollectionBannerProps } from '../../models/collectionBanner'

type OwnProps = {
  collectionSlug: string
  shuffle?: boolean
}

type MethodProps = {
  sliderScrollHandler: (collection: Collection) => void
}

const eventHandler = (collection: Collection) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return (dispatch: any, getState: () => ApplicationState) => {
    dataLayerPushEvent('customScrollEvent', '', collection.title)
  }
}

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps): FieldProps => {
  const collection = state.data.collections[ownProps.collectionSlug]
  if (collection === undefined) {
    return {}
  }

  const currentEvent = currentEventSelector(state)

  // get category name by collection categorySlug, get category link by slug
  let categoryName
  let categoryLink
  if (currentEvent !== undefined && currentEvent.categories !== undefined) {
    const matchedCollection = state.data.collections[collection.slug]
    if (matchedCollection !== undefined && matchedCollection.categorySlug !== undefined) {
      const matchedCategory = findCategoryBySlug(currentEvent.categories, matchedCollection.categorySlug)
      categoryLink = getCategoryUrlFromCategories(currentEvent.categories, matchedCollection.categorySlug)
      if (matchedCategory !== undefined) {
        categoryName = matchedCategory.category.name
      } else {
        categoryName = matchedCollection.title
      }
    }
  }

  let deals
  if (collection.deals !== undefined) {
    deals = collection.deals
      .sort((a, b) => Number(a.position) - Number(b.position))
      .map(deal => state.data.deals[deal.slug])
      .filter(deal => deal !== undefined)
  }

  // if (deals) {
  //   deals = deals.filter(deal => {
  //     const dealStartTime =
  //       deal && deal.dealStartTime
  //         ? deal.dealStartTime
  //         : currentEvent && parseInt(moment(moment.unix(currentEvent.start).format('YYYY-MM-DD')).format('X'))
  //     const dealEndTime =
  //       deal && deal.dealEndTime ? deal.dealEndTime : currentEvent && parseInt(currentEvent.end.toString())

  //     if (deal && state.account.isAdmin) {
  //       return true
  //     }

  //     if (deal && deal.isExclusive) {
  //       return true
  //     }

  //     if (
  //       dealStartTime &&
  //       dealStartTime <= state.status.networkTime &&
  //       dealEndTime &&
  //       dealEndTime > state.status.networkTime
  //     )
  //       return true
  //     return false
  //   })
  // }

  const currentCollectionSlug = collection.slug

  const collectionBanner: CollectionBannerProps | undefined = state.data.banners.find((banner: Banner) => {
    if (banner?.collectionSlug === currentCollectionSlug && banner?.type === 'HOME_COLLECTION_LEADERBOARD') {
      return {
        desktopImage: banner.desktopImage,
        mobileImage: banner.mobileImage,
        alt: banner.alt,
        target: banner.target,
      }
    }
  })
  return {
    collection,
    deals,
    categoryName,
    categoryLink,
    collectionBanner,
    shuffle: ownProps.shuffle,
  }
}

const actions: MethodProps = {
  sliderScrollHandler: eventHandler,
}

export default connect(mapStateToProps, actions)(CollectionSlider)
